function Header() {
  return (
    <div className='col-span-2 mx-8 sm:mx-0 text-center mt-8'>
      <h4 className='text-primary text-lg font-regular sm:text-lg font-Roboto uppercase'>
        Pricing Plans
      </h4>
      <h1 className='text-secondary text-4xl font-Roboto font-bold '>
        Hello Affordable.
      </h1>
    </div>
  )
}
export default Header
