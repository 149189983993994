import { useContext, useEffect, useState } from 'react'

import TotalPriceContext from '../context/TotalPriceContext'
import { getPropertyTypes } from '../data'
import { calculateCampsitePricing } from '../utilities/helpers'

function PropertyTypesList() {
  const { pricePackage, updatePricePackage } = useContext(TotalPriceContext)
  const [width, setWidth] = useState(window.innerWidth)

  const propertyTypes = getPropertyTypes()

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => window.removeEventListener('resize', handleWindowSizeChange)
  })

  const handleClick = (event) => {
    let propertyType
    if (event.target.nodeName === 'SELECT') {
      propertyType = event.target.selectedOptions[0].dataset.propType
    } else {
      propertyType = event.target.dataset.propType
    }
    if (propertyType === 'campsite') {
      const getCampsitePrice = calculateCampsitePricing(
        pricePackage.numRooms,
        pricePackage.plan
      )
      updatePricePackage({
        type: propertyType,
        totalPrice: getCampsitePrice,
        numRooms: 40,
        unit: 'pitch',
      })
      return
    } else {
      updatePricePackage({
        type: propertyType,
        numRooms: 1,
        unit: 'room',
      })
    }
  }

  return (
    <>
      {width > 768 ? (
        <div className='tabs group text-secondary mt-6 mb-6 justify-stretch font-bold'>
          {propertyTypes.map((type, index) => (
            <div
              key={index}
              data-prop-type={type.dataId}
              className={`tab border-b border-gray-400 text-secondary text-sm md:text-md pb-10 flex-1 transition ease-in-out delay-75 ${
                pricePackage.type === type.dataId ? 'tab-active' : ''
              }`}
              onClick={handleClick}
            >
              {type.title}
            </div>
          ))}
        </div>
      ) : (
        <div className='flex justify-center my-4 mx-12'>
          <select
            className='select w-80 md:w-96 select-secondary text-secondary'
            onChange={handleClick}
          >
            {propertyTypes.map((type, index) => (
              <option key={index} data-prop-type={type.dataId}>
                {type.title}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}
export default PropertyTypesList
