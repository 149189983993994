import { useState, useContext } from 'react'
import TotalPriceContext from '../context/TotalPriceContext'

const PricePeriod = () => {
  const { pricePackage, updatePricePackage } = useContext(TotalPriceContext)
  const [plan, setplan] = useState(pricePackage.plan)

  const handleChange = (e) => {
    setplan(e.target.value)
    updatePricePackage({
      plan: e.target.value,
    })
  }

  const planPeriodClasses = {
    annually: 'text-secondary',
    monthly: 'text-lightgray',
  }

  return (
    <div className='flex justify-center -mt-2 md:mt-4 mx-0 sm:mx-6'>
      <div className='form-control basis-1/2 justify-center'>
        <label className='label cursor-pointer justify-center md:justify-end md:mr-4 md:flex-row'>
          <input
            id='plan-monthly'
            type='radio'
            name='monthly'
            className='radio border border-gray-200 checked:bg-secondary checked:border-secondary'
            value='monthly'
            checked={plan === 'monthly'}
            onChange={handleChange}
          />
          <span
            className={`label-text ml-4 text-md lg:text-xl font-regular ${
              plan === 'monthly' ? 'text-secondary' : 'text-lightgray'
            }`}
          >
            Pay Monthly
          </span>
        </label>
      </div>
      <div className='form-control basis-1/2'>
        <label className='label cursor-pointer justify-center md:justify-start md:flex-row'>
          <input
            id='plan-annually'
            type='radio'
            name='annually'
            className={`radio border-lightgray ${
              plan === 'annually'
                ? 'checked:bg-secondary'
                : 'checked:bg-gray-400'
            } checked:border-secondary`}
            checked={plan === 'annually'}
            value='annually'
            onChange={handleChange}
          />
          <div
            className={`flex flex-col md:flex-row
            }`}
          >
            <span
              className={`text-xs ml-4 md:ml-2 md:text-md lg:text-xl italic uppercase ${planPeriodClasses[plan]}`}
            >
              Save 10%
            </span>
            <span
              className={`label-text ml-4 md:ml-2 text-md lg:text-xl font-regular ${planPeriodClasses[plan]}`}
            >
              Pay Annually
            </span>
          </div>
        </label>
      </div>
    </div>
  )
}

export default PricePeriod
