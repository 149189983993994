import { Helmet } from 'react-helmet'

// Components
import Header from './components/Header'
import PricingPlan from './components/PricingPlan'
import RoomNumInput from './components/RoomNumInput'
import PropertyTypesList from './components/PropertyTypesList'
import PricePeriod from './components/PricePeriod'
import ClarityScript from './components/ClarityScript'

function App() {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Innstyle Pricing Calculator</title>
        <meta name='robots' content='noindex' data-react-helmet='true' />
        <ClarityScript />
      </Helmet>
      <div className='container bg-transparent max-w-screen-xl px-4 mx-auto'>
        <Header />
        <PropertyTypesList />
        <RoomNumInput />
        <PricePeriod />
        <PricingPlan />
      </div>
      <div className='bg-secondary min-h-[29rem] py-24 -mt-[22rem]'></div>
    </>
  )
}

export default App
