export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export function calculateCampsitePricing(numRooms, selectedPeriod) {
  let campsiteTotalPrice = calculateRoomPrice(numRooms)
  campsiteTotalPrice = applyDiscount(campsiteTotalPrice, selectedPeriod)
  return campsiteTotalPrice
}

function calculateRoomPrice(numRooms) {
  if (numRooms > 0 && numRooms < 25) {
    return parseFloat(25.0)
  } else if (numRooms > 24 && numRooms < 50) {
    return 50.0
  } else if (numRooms > 49 && numRooms < 75) {
    return 75.0
  } else {
    return 100.0
  }
}

function applyDiscount(campsiteTotalPrice, selectedPeriod) {
  if (selectedPeriod === 'annually') {
    return campsiteTotalPrice * 12 * 0.9
  }
  return campsiteTotalPrice
}

export function getDiscountedAmount(total) {
  return total - total * 0.1
}
